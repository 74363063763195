import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { NavDropdown } from 'react-bootstrap';
import PrivateImage from 'helpers/PrivateImage';

const Header = ({ setActiveSubMenu }) => {

    const storedData = localStorage.getItem('loginData');
    const data = storedData ?  JSON.parse(atob(storedData)) : null;


    const handleSubMenuClick = () => {
        if (document.body.classList.contains('mini-sidebar')) {
            document.body.classList.remove('mini-sidebar');
        } else {
            document.body.classList.add('mini-sidebar');
            setActiveSubMenu(null);
        }
    };
    const handleMobileMenuClick = () => {
        const element = document.getElementById('mobilemenu');

        if (element.classList.contains('slide-nav')) {
            element.classList.remove('slide-nav');
        } else {
            element.classList.add('slide-nav');
            setActiveSubMenu(null);
        }
    };
    const Logout = () => {
    localStorage.removeItem('token');
    };


    return (
        <Fragment>
            <div className="header">

                <div className="header-left">
                    <Link to="/" className="logo">
                        <img src="https://smarthr.dreamstechnologies.com/html/template/assets/img/logo.png" width="40" height="40" alt="Logo" />
                    </Link>
                    <Link to="/" className="logo2">
                        <img src="https://smarthr.dreamstechnologies.com/html/template/assets/img/logo2.png" width="40" height="40" alt="Logo" />
                    </Link>
                </div>

                <span id="toggle_btn" to="" >
                    <span className="bar-icon" onClick={handleSubMenuClick}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </span>
                </span>

                <div className="page-title-box">
                    <h3>{data?.companyName === null ? "Corpfield Technology" : data?.companyName}</h3>
                </div>

                <Link id="mobile_btn" className="mobile_btn " onClick={handleMobileMenuClick}><i className="fa-solid fa-bars"></i></Link>

                <ul className="nav user-menu ">
                    <li className="nav-item">
                        <div className="top-nav-search">
                            <Link to="" className="responsive-search">
                                <i className="fa-solid fa-magnifying-glass"></i>
                            </Link>
                            <form action="">
                                <input className="form-control" type="text" placeholder="Search here" />
                                <button className="btn" type="submit"><i className="fa-solid fa-magnifying-glass"></i></button>
                            </form>
                        </div>
                    </li>
                    <li className="nav-item dropdown has-arrow main-drop">
                        <NavDropdown title={
                            <React.Fragment>
                                <span className="user-img">
                                   {data?.profileImageUrl !== null ? <PrivateImage privateUrl={data?.profileImageUrl} imgType={1} className="header-image"/>: <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/2c/Default_pfp.svg/800px-Default_pfp.svg.png" alt="User" />}
                                    <span className="status online"></span>
                                </span>
                                &nbsp;&nbsp; <span className="admin-cc">{data?.userName}</span> &nbsp;&nbsp;
                            </React.Fragment>
                        } id="basic-nav-dropdown" className="dropdown-toggle nav-link">
                            <Link className="dropdown-item" to="/profile">My Profile</Link>
                            <Link className="dropdown-item" to="/settings">Settings</Link>
                            <Link className="dropdown-item" to="/"  onClick={Logout}>Logout</Link>
                        </NavDropdown>
                    </li>
                </ul>

                <div className="dropdown mobile-user-menu">
                    <Link to="" className="nav-link dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i className="fa-solid fa-ellipsis-vertical"></i></Link>
                    <div className="dropdown-menu dropdown-menu-right">
                        <Link className="dropdown-item" to="/">My Profile</Link>
                        <Link className="dropdown-item" to="/">Settings</Link>
                        <Link className="dropdown-item" to="/"  onClick={Logout}>Logout</Link>
                    </div>
                </div>

            </div>
        </Fragment>
    )
}

export default Header