import React, { Fragment, useEffect } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';


const Sidebar = ({ activeSubMenu, setActiveSubMenu }) => {

    const location = useLocation();

    const handleSubMenuClick = (index) => {
        setActiveSubMenu(prevActiveSubMenu => prevActiveSubMenu === index ? null : index);
    };

    useEffect(() => {
        if (activeSubMenu !== null) {
            document.body.classList.remove('mini-sidebar');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeSubMenu])

    useEffect(() => {
        const pathMap = {
            "/employees/all-employees": 2,
            "/employees/holidays": 2,
            "/employees/leaves-admin": 2,
            "/employees/leaves-employee": 2,
            "/employees/leaves-settings": 2,
            "/employees/attendance-admin": 2,
            "/employees/attendance-employee": 2,
            "/employees/department": 2,
            "/employees/designation": 2,
            "/employees/attendance" : 2,
            "/employees/timesheet": 2,
            "/employees/schedule": 2,
            "/employees/overtime": 2,
            "/employees/dashboard": 2,
            "/employees/project": 2,
            "/employees/client": 2,
            "/employees/invoice" :2,

            "/hr/employee-salary": 3,
            "/hr/payslip": 3,
            "/hr/pay-roll-items/additions": 3,
            "/hr/pay-roll-items/deductions": 3,
            "/hr/pay-roll-items/over-time": 3,

            "/settings": 4,
            "/settings/custom-field-settings": 4,
            "/settings/roles": 4
        };
        setActiveSubMenu(pathMap[location.pathname]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);

    return (
        <Fragment>
            <div className="sidebar" id="sidebar">
                <div className="sidebar-inner slimscroll">
                    <div id="sidebar-menu" className="sidebar-menu" >
                        <ul className="sidebar-vertical">
                            <li className="menu-title">
                                <span>Main</span>
                            </li>
                            <li className={window.location.pathname === "/dashboard" ? "active" : ""}>
                                <Link to="/dashboard" className={`${activeSubMenu === 1 ? 'subdrop' : ''}`} onClick={() => handleSubMenuClick(1)}>
                                    <i className="la la-dashboard"></i> <span> Dashboard</span>
                                </Link>
                            </li>
                            <li className="menu-title">
                                <span>Employee</span>
                            </li>
                            <li className="submenu">
                                <Link className={`active  ${activeSubMenu === 2 ? 'subdrop' : ''}`} onClick={() => handleSubMenuClick(2)}>
                                    <i className="la la-user"></i> <span> Employees</span> <span className="menu-arrow"></span>
                                </Link>
                                {activeSubMenu === 2 && (
                                    <ul style={{ display: "block" }}>
                                        <li><NavLink to="/employees/all-employees">All Employees</NavLink></li>
                                        {/* <li><NavLink to="/employees/holidays">Holidays</NavLink></li>
                                        <li><NavLink to="/employees/leaves-admin">Leaves (Admin)</NavLink></li>
                                        <li><NavLink to="/employees/leaves-employee">Leaves (Employee)</NavLink></li>
                                        <li><NavLink to="/employees/leaves-settings">Leave Settings</NavLink></li>
                                        <li><NavLink to="/employees/attendance-admin">Attendance (Admin)</NavLink></li>
                                        <li><NavLink to="/employees/attendance-employee">Attendance (Employee)</NavLink></li> */}
                                        <li><NavLink to="/employees/department">Departments</NavLink></li>
                                        <li><NavLink to="/employees/designation">Designations</NavLink></li>
                                        <li><NavLink to="/employees/attendance">Attendance</NavLink></li>
                                        <li><NavLink to="/employees/invoice">Invoice</NavLink></li>
                                        <li><NavLink to="/employees/schedule">Shift & Schedule</NavLink></li>
                                        <li><NavLink to="/employees/project">Projects</NavLink></li>
                                        <li><NavLink to="/employees/client">Client</NavLink></li>
                                        {/* <li><NavLink to="/employees/timesheet">Timesheet</NavLink></li>
                                        <li><NavLink to="/employees/shify-schedule">Shift & Schedule</NavLink></li>
                                        <li><NavLink to="/employees/overtime">Overtime</NavLink></li>
                                        <li><NavLink to="/employees/dashboard">Dashboard</NavLink></li> */}
                                    </ul>
                                )}
                            </li>
                            {/* <li className="menu-title">
                                <span>HR</span>
                            </li>
                            <li className="submenu">
                                <Link to="#" className={`active  ${activeSubMenu === 3 ? 'subdrop' : ''}`} onClick={() => handleSubMenuClick(3)}>
                                    <i className="la la-money"></i> <span> Payroll </span> <span className="menu-arrow"></span>
                                </Link>
                                {activeSubMenu === 3 && (
                                    <ul style={{ display: "block" }}>
                                        <li><NavLink to="/hr/employee-salary"> Employee Salary </NavLink></li>
                                        <li><NavLink to="/hr/payslip"> Payslip </NavLink></li>
                                        <li><NavLink to="/hr/pay-roll-items"> Payroll Items </NavLink></li>
                                    </ul>
                                )}
                            </li> */}
                            <li className="menu-title">
                                <span>Settings</span>
                            </li>
                            <li className="submenu">
                                <Link to="/settings/company-settings">
                                    <i className="la la-building"></i> <span> Company Settings </span>
                                </Link>
                                <Link to="/settings/custom-field-settings">
                                    <i className="la la-pencil-square"></i> <span> Custom Field Settings </span>
                                </Link>
                                <Link to="/settings/roles">
                                    <i className="la la-user-tag"></i> <span> Roles </span>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Sidebar;
